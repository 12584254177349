import { hasAccessToApp } from '~/shared/api/auth.api';

export const publicRoutes = [
  {
    path: '/not-found',
    name: 'object-not-found',
    component: () => import('~/pages/ObjectNotFound.vue'),
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: () => import('~/pages/ForbiddenPage.vue'),
    beforeEnter: (to, from, next) => {
      if (hasAccessToApp()) {
        next({ name: 'home' });
      } else next();
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'not-found',
    component: () => import('~/pages/PageNotFound.vue'),
  },
];
