import Viewer from 'viewerjs';

export default {
  mounted(el, binding) {
    const viewer = new Viewer(el, binding.value);
    el._viewer = viewer;
  },
  beforeUnmount(el) {
    if (el._viewer) {
      el._viewer.destroy();
    }
  },
};
