import { apiClient, setAuthToken } from '~/shared/api/apiClient';
import router from '~/app/router';
import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: process.env.VUE_APP_KEYCLOAK_URI,
  realm: 'employees',
  clientId: 'teams-web-frontend',
};

export const keycloak = new Keycloak(keycloakConfig);

const getAuthToken = () => `Bearer ${keycloak.token}`;

export const init = async () => {
  return await keycloak
    .init({
      useNonce: true,
      adapter: 'default',
      checkLoginIframe: false,
      onLoad: 'login-required',
    })
    .then(response => {
      setAuthToken(getAuthToken());
      return response;
    });
};

export const login = async () => {
  return await keycloak
    .login({
      redirectUri: `${location.origin}${router.currentRoute.value.path || ''}`,
      locale: 'ru',
    })
    .then(() => {
      setAuthToken(getAuthToken());
    });
};

export const refreshToken = async () => {
  await keycloak.updateToken(10);
  const token = getAuthToken();
  setAuthToken(token);
  return token;
};

export const hasAccessToApp = () => {
  return keycloak.tokenParsed?.resource_access?.teams?.roles.includes('login') ?? false;
};

export const logoutRequest = async () => {
  delete apiClient.defaults.headers['Authorization'];
  await keycloak.logout();
};
const getUser = () => apiClient.get(`hr/me`);

export { getUser };
