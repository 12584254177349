import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getUser } from '~/shared/api/auth.api';
import { parseErrorsFromResponse } from '~/shared/utils/parsers';
import { $toast } from '~/main';

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null);
  const getUserFunc = async () => {
    try {
      const response = await getUser();
      user.value = response?.data.data;
    } catch (err) {
      $toast.show({ title: 'Error', subTitle: parseErrorsFromResponse(err), type: 'error' });
    }
  };

  return { getUserFunc, user };
});
