import { ref, reactive, computed } from 'vue';
import { defineStore } from 'pinia';
import { getApp } from '~/shared/api/app.api';
import { getEmployee } from '~/shared/api/employees.api';
import { parseErrorsFromResponse } from '~/shared/utils/parsers';
import { $toast } from '~/main';

export const useAppStore = defineStore('app', () => {
  const appLoading = ref(false);
  const globals = ref(null);
  const employeeData = reactive({
    data: null,
    error: null,
    loading: false,
  });

  const colleagueFeedbacksCurrentTab = ref('sent');

  const getAppFunc = async params => {
    try {
      appLoading.value = true;
      const res = await getApp(params);
      globals.value = res.data;
    } catch (err) {
      $toast.show({ title: 'Error', subTitle: parseErrorsFromResponse(err), type: 'error' });
    } finally {
      appLoading.value = false;
    }
  };

  const getEmployeeDataFunc = async () => {
    try {
      employeeData.loading = true;
      const res = await getEmployee();
      employeeData.data = res.data;
    } catch (err) {
      employeeData.error = parseErrorsFromResponse(err);
    } finally {
      employeeData.loading = false;
    }
  };
  const notificationTypes = computed(() => {
    return globals.value?.notification_types;
  });

  const colleagueCurrentTab = computed(() => colleagueFeedbacksCurrentTab);

  const notificationTypeOptions = computed(() => {
    return [
      {
        title: 'Все',
        value: null,
      },
      ...Object.values(globals.value?.notification_types).map(i => {
        return { title: i.name, value: i.key };
      }),
    ];
  });
  const workArrangementRequestStatusOptions = computed(() => {
    return [
      { title: 'Все заявки', value: '' },
      ...Object.values(globals.value?.work_arrangement_request_statuses).map(item => ({
        title: item.name?.charAt(0).toUpperCase() + item.name?.slice(1),
        value: item.id,
      })),
    ];
  });
  const workArrangementRequestTypeOptions = computed(() => {
    return [
      { title: 'Все', value: '' },
      ...Object.values(globals.value?.work_arrangement_request_types).map(item => ({
        title: item.name,
        value: item.id,
      })),
    ];
  });
  const positions = computed(() => {
    return globals.value?.positions
      ?.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
      )
      .map(item => {
        return { title: item.name, value: item.id };
      });
  });
  const departments = computed(() => {
    return globals.value?.departments
      ?.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
      )
      .map(item => {
        return { title: item.name, value: item.id };
      });
  });
  const outBotStatusOptions = computed(() => {
    return [...Object.values(globals.value?.statuses ?? []).map(item => ({ title: item.name, value: item.id }))];
  });
  const outBotStatuses = computed(() => {
    return globals.value?.statuses;
  });
  const teams = computed(() => {
    return globals.value?.teams
      ?.sort((a, b) =>
        a.name.toUpperCase() > b.name.toUpperCase() ? 1 : b.name.toUpperCase() > a.name.toUpperCase() ? -1 : 0
      )
      .map(item => {
        return { title: item.name, value: item.id };
      });
  });
  const colleaguesTeams = computed(() => {
    return globals.value.teams || [];
  });
  const ticketTags = computed(() => {
    return globals.value.tags?.map(element => {
      return {
        name: element.title_ru,
        value: element.id,
      };
    });
  });

  const TICKET_STATUSES = computed(() => {
    return (
      globals.value?.ticket_statuses.reduce((acc, current) => {
        acc[current.key] = current.name;
        return acc;
      }, {}) ?? []
    );
  });
  const TICKETS_STATUS_COLORS = computed(() => {
    return (
      globals.value?.ticket_statuses.reduce((acc, current) => {
        acc[current.key] = current.color;
        return acc;
      }, {}) ?? []
    );
  });

  const tokenTypeDevice = computed(() => {
    return globals.value?.token_from_types?.web;
  });

  const TICKET_CURRENT_STATUSES = computed(() => {
    return globals.value?.ticket_statuses?.map(item => {
      return {
        title: item.name,
        value: item.key,
      };
    });
  });

  const statusType = computed(() => {
    return globals.value?.work_arrangement_request_types;
  });
  const turvWorkingDayStatuses = computed(() => {
    return globals.value?.working_day_statuses;
  });
  const ticketCreatedFrom = computed(() => {
    return globals.value?.ticket_created_from;
  });
  const getEmployeeData = computed(() => {
    return employeeData;
  });

  return {
    getAppFunc,
    getEmployeeData,
    notificationTypes,
    notificationTypeOptions,
    getEmployeeDataFunc,
    workArrangementRequestStatusOptions,
    workArrangementRequestTypeOptions,
    positions,
    departments,
    outBotStatusOptions,
    outBotStatuses,
    teams,
    tokenTypeDevice,
    statusType,
    turvWorkingDayStatuses,
    ticketTags,
    globals,
    ticketCreatedFrom,
    colleaguesTeams,
    colleagueCurrentTab,
    TICKET_STATUSES,
    TICKETS_STATUS_COLORS,
    TICKET_CURRENT_STATUSES,
  };
});
