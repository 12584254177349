import { apiClient } from '~/shared/api/apiClient';

const END_POINT = '/hr';
/**
 * Get base data that used everywhere in app
 * @returns { Promise<AxiosResponse<any>> }
 */
const getApp = params => apiClient.get(`${END_POINT}/app`, { params });

/**
 * Send firebase cloud messaging client token
 * @params {Object}
 * @returns { Promise<AxiosResponse<any>> }
 */
const sendFirebaseTokenRequest = params => apiClient.post(`${END_POINT}/store-token`, params);

export { getApp, sendFirebaseTokenRequest };
