import { checkType } from '@packages/utils';
import { format, formatDistanceStrict, formatDistanceToNow, parseISO, intervalToDuration } from 'date-fns';
import { ru } from 'date-fns/locale';

export function calculateAge(birthday) {
  const birthdayDate = new Date(birthday);
  const ageDifMs = Date.now() - birthdayDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function calculateDaysLeftToBirthday(birthday) {
  const today = new Date();
  const bday = new Date(birthday);
  let upcomingBday = new Date(today.getFullYear(), bday.getMonth(), bday.getDate());
  if (today > upcomingBday) upcomingBday.setFullYear(today.getFullYear());
  const one_day = 24 * 60 * 60 * 1000;
  let daysLeft = Math.ceil((upcomingBday.getTime() - today.getTime()) / one_day);
  return daysLeft + 1;
}

export function formatDateByDayMonthYear(date) {
  if (!date) return '';
  return new Date(date).toLocaleDateString('ru', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}

export function convertMinsToHrsMins(mins) {
  if (checkType(mins, 'number')) {
    const seconds = mins * 60;
    const res = intervalToDuration({ start: 0, end: seconds * 1000 });
    return mins < 60 ? `${res.minutes} мин` : `${res.hours} ч ${res.minutes} мин`;
  }
}

export function readableDate(date) {
  if (!date) return '';
  return format(parseISO(date), 'dd MMM yyyy', { locale: ru });
}

export function readableDateWithTime(date) {
  if (!date) return '';
  return format(parseISO(date), 'dd MMM yyyy HH:mm', { locale: ru });
}

export function readableDateTime(date) {
  if (!date) return '';
  return format(new Date(date), 'HH:mm');
}

export function parseDate(date) {
  if (!date) return '';
  return format(new Date(date), 'yyyy-MM-dd');
}

export function parseDateFormat(date) {
  if (!date) return '';
  return format(new Date(date), 'yyyy.MM.dd');
}

export function readableMonth(date) {
  if (!date) return '';
  return format(parseISO(date), 'MMM yyyy', { locale: ru });
}

export function readableDateMonth(date) {
  if (!date) return '';
  return format(parseISO(date), 'dd MMM', { locale: ru });
}

export function readableDateYear(date) {
  if (!date) return '';
  return format(parseISO(date), 'MMM', { locale: ru });
}

export function formatDistanceDate(date) {
  if (!date) return '';
  return formatDistanceToNow(new Date(date), { addSuffix: true, locale: ru });
}

export const readableTime = date => {
  if (!date) return '';
  return format(parseISO(date), 'HH:mm BBB', { locale: ru });
};

export const formatDistanceBirthDay = colleague => {
  let date_now = new Date();
  let birth_date = new Date(colleague);
  birth_date.setFullYear(date_now.getFullYear());
  if (date_now.getDate() === birth_date.getDate() && birth_date.getMonth() === date_now.getMonth()) {
    return 'Сегодня';
  } else if (date_now.getDate() + 1 === birth_date.getDate() && birth_date.getMonth() === date_now.getMonth()) {
    return 'Завтра';
  } else if (birth_date.getTime() < date_now.getTime()) {
    birth_date.setFullYear(date_now.getFullYear() + 1);
  }
  return formatDistanceStrict(birth_date, date_now, {
    locale: ru,
    addSuffix: true,
    roundingMethod: 'floor',
    unit: 'day',
  });
};

export function secondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  if (remainingSeconds < 10) {
    return `${minutes}:0${remainingSeconds}`;
  } else {
    return `${minutes}:${remainingSeconds}`;
  }
}
