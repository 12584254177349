import { apiClient } from '~/shared/api/apiClient';

const END_POINT = 'hr/employee';
const HR_END_POINT = 'hr';

/**
 * Get AllEmployees data
 * @returns { Promise<AxiosResponse<any>> }
 */
const getEmployee = () => apiClient.get(`${HR_END_POINT}/employee-data`);

/**
 * Get Employees work-schedule data
 * @returns { Promise<AxiosResponse<any>> }
 */
const getEmployeeWorkSchedule = employeeId => apiClient.get(`${END_POINT}/${employeeId}/work-schedule`);

/**
 * Update Employee's Telegram Link
 * @param {string} payload
 * @returns { Promise<ResponseType<string>> }
 */
const updateTelegramLinkRequest = payload =>
  apiClient.put(`${END_POINT}/update-telegram-link`, { telegram_link: payload });

/**
 * Update Employee's Email Address
 * @param {string} payload
 * @returns { Promise<ResponseType<string>> }
 */
const updateEmailAddressRequest = payload => apiClient.put(`${END_POINT}/update-email`, { email: payload });

/**
 * Get AllEmployees OUTBOT data
 * @returns { Promise<AxiosResponse<any>> }
 */
const getEmployeeOutBot = params => apiClient.get(`${END_POINT}/out-bot-cases`, { params });

/**
 * Get AllEmployees data
 * @returns { Promise<AxiosResponse<any>> }
 */
const getEmployeeById = employeeId => apiClient.get(`${HR_END_POINT}/manager/employees/${employeeId}`);

/**
 * Get Employee Rating-Chart-Data
 * @returns { Promise<AxiosResponse<any>> }
 * @param employeeId
 * @param params
 */
const getRatingChartData = (employeeId, params) =>
  apiClient.get(`${HR_END_POINT}/employees/${employeeId}/rating-chart-data`, { params });

/**
 * Get team ratings data
 * @returns { Promise<AxiosResponse<any>> }
 * @param params
 */
const getTeamRatings = params => apiClient.get(`${HR_END_POINT}/manager/employees/ratings`, { params });

/**
 * Get Employee ratings data
 * @returns { Promise<AxiosResponse<any>> }
 * @param employeeId
 * @param params
 */
const getRatings = (employeeId, params) =>
  apiClient.get(`${HR_END_POINT}/employees/${employeeId}/rating-history`, { params });

/**
 * Get Employee Responsibilities
 * @returns { Promise<AxiosResponse<any>> }
 */
const getEmployeeResponsibilities = () => apiClient.get(`${END_POINT}/responsibilities`);

/**
 * set score to employee
 * @param {Object} params
 * @returns { Promise<AxiosResponse<any>> }
 */
const setScore = params => apiClient.post(`${HR_END_POINT}/ratings`, params);

/**
 * get Employee Vacations
 * @returns { Promise<AxiosResponse<any>> }
 */
const employeeVacations = () => apiClient.get(`${HR_END_POINT}/vacations`);

/**
 * Hide Employee Birth Date
 * @param {Object} params
 * @returns { Promise<AxiosResponse<any>> }
 */
const switchEmployeeBirthDateRequest = params => apiClient.put(`${END_POINT}`, params); /**


 * Get Employee Meetings
 * @param {Object} params
 * @returns { Promise<AxiosResponse<any>> }
 */
const getEmployeeMeetings = params => apiClient.get('dotnet/employee_reviews/my_conversations', { params });

export {
  getEmployee,
  getEmployeeById,
  getRatingChartData,
  getRatings,
  getTeamRatings,
  setScore,
  getEmployeeResponsibilities,
  getEmployeeOutBot,
  updateTelegramLinkRequest,
  employeeVacations,
  switchEmployeeBirthDateRequest,
  updateEmailAddressRequest,
  getEmployeeWorkSchedule,
  getEmployeeMeetings,
};
