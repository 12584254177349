<template>
  <div class="dark:bg-gray-900 dark:text-white">
    <router-view />
  </div>
</template>

<script setup>
  import { initializeApp } from 'firebase/app';
  import { useTheme } from '~/shared/utils/hooks/useTheme';
  import { $toast } from '~/main';

  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: 'alif-teams',
    storageBucket: 'alif-teams.appspot.com',
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  };

  if ('Notification' in window) {
    if (Notification.permission !== 'denied') {
      initializeApp(firebaseConfig);
    }
  } else {
    $toast.info({ title: 'This browser does not support desktop notification', type: 'info' });
  }

  const { theme, setTheme } = useTheme();
  setTheme(theme.value);
</script>
